import imagesloaded from "imagesloaded";
import AOS from "aos";
import Flickity from "flickity";
import Splitting from "splitting";

///////////////////////////////
// Initialize
///////////////////////////////

// AOS
AOS.init({
  duration: 800
});

// Splitting
Splitting();

// Flickity
window.Flickity = Flickity;

// Imagesloaded
window.imagesLoaded = imagesloaded;

///////////////////////////////
// Functions
///////////////////////////////

// Page Section Background Image Preloader
imagesloaded(".page-section__background", { background: true }, function() {
  const backgrounds = document.querySelectorAll(".page-section__background");
  backgrounds.forEach(item => {
    item.classList.add("page-section__background--loaded");
  });
});

// Fade out section headers
const header = document.querySelector(".page-header .page-section__background");
if (header) {
  window.addEventListener("scroll", function(e) {
    const offset = $(window).scrollTop();
    if (offset <= header.clientHeight) {
      header.classList.add("scrolling");
      header.style.opacity = 1 - offset / header.clientHeight;
    } else {
      header.classList.remove("scrolling");
    }
  });
}

// Headroom
var navbarWrapper = document.querySelector('.headroom');
var navbar = document.querySelector('.navbar');

var headroom = new Headroom(navbarWrapper, {
  tolerance: {
    up: 10,
    down: 10,
  },
});
headroom.init();


//Navigation
var app = function () {
  var body = undefined;
  var menu = undefined;
  var menuItems = undefined;
  var init = function init() {
    body = document.querySelector('body');
    menu = document.querySelector('.menu-icon');
    menuItems = document.querySelectorAll('.nav__list-item');
    applyListeners();
  };
  var applyListeners = function applyListeners() {
    menu.addEventListener('click', function () {
      return toggleClass(body, 'nav-active');
    });
  };
  var toggleClass = function toggleClass(element, stringClass) {
    if (element.classList.contains(stringClass)) element.classList.remove(stringClass);else element.classList.add(stringClass);
  };
  init();
}();




//------------------------------------//
//Toplevel Navigation Underline Hover//
//------------------------------------//
const target = document.querySelector(".target");
const links = document.querySelectorAll("#menu-menu-1 a");
const colors = ["black"];

function mouseenterFunc() {
  if (!this.parentNode.classList.contains("hover-active")) {
    for (let i = 0; i < links.length; i++) {
      if (links[i].parentNode.classList.contains("hover-active")) {
        links[i].parentNode.classList.remove("hover-active");
      }
      links[i].style.opacity = "0.7";
    }

    this.parentNode.classList.add("hover-active");
    this.style.opacity = "1";

    const width = this.getBoundingClientRect().width;
    const height = this.getBoundingClientRect().height;
    const left = this.getBoundingClientRect().left + window.pageXOffset;
    // const top = this.getBoundingClientRect().top + window.pageYOffset;
    const color = colors[Math.floor(Math.random() * colors.length)];

    target.style.width = `${width}px`;
    target.style.height = `10px`;
    target.style.left = `${left}px`;
    target.style.top = `${top}px`;
    target.style.borderColor = color;
    target.style.transform = "none";
  }
}

for (let i = 0; i < links.length; i++) {
  // links[i].addEventListener("click", (e) => e.preventDefault());
  links[i].addEventListener("mouseenter", mouseenterFunc);
}

function resizeFunc() {
  const active = document.querySelector("#menu-main-menu li.active");

  if (active) {
    const left = active.getBoundingClientRect().left + window.pageXOffset;
    // const top = active.getBoundingClientRect().top + window.pageYOffset;

    target.style.left = `${left}px`;
    // target.style.top = `${top}px`;
  }
}

window.addEventListener("resize", resizeFunc);



//------------------------------------//
//Overlay Navigation Underline//
//------------------------------------//
class Underliner {
  constructor(selector, color1, color2, thickness1, thickness2, strokeLinecap, rtl) {
    this.links = document.querySelectorAll(selector)
    this.fill = 'transparent';
    this.color1 = color1;
    this.color2 = color2;
    this.thickness1 = thickness1;
    this.thickness2 = thickness2;
    this.strokeLinecap = strokeLinecap;
    this.rtl = rtl;
    this.init();
  }

  init() {
    let self = this;

    self.links.forEach(function (link) {
      let linkWidth = parseInt(link.offsetWidth);
      let svg = self.createSVG(linkWidth);
      self.insertAfter(svg, link);
    });
  }

  setPath(pathD, color, thickness, strokeLinecap) {
    const path = document.createElementNS("http://www.w3.org/2000/svg", "path");

    path.setAttribute("d", pathD);
    path.setAttribute("fill", this.fill);
    path.setAttribute("stroke", color);
    path.setAttribute("stroke-width", thickness);
    path.setAttribute("stroke-linecap", strokeLinecap);
    path.setAttribute("stroke-dasharray", path.getTotalLength() + 10);
    path.setAttribute("stroke-dashoffset", path.getTotalLength() + 10);

    return path;
  }

  randomizePath(linkWidth) {
    let moveYMin = 3;
    let moveYMax = 9;

    let curveXMin = 11;
    let curveXMax = linkWidth; /* Width of the link */
    let curveYMin = 4;
    let curveYMax = linkWidth * 0.08; /* Making the quadratic propotional to the link width */
    //let curveYMax = 20

    let endYMin = 5;
    let endYMax = 5;

    let moveY = Math.floor(Math.random() * (moveYMax - moveYMin)) + moveYMin;
    let curveX = Math.floor(Math.random() * (curveXMax - curveXMin)) + curveXMin;
    let curveY = Math.floor(Math.random() * (curveYMax - curveYMin)) + curveYMin;
    let endY = Math.floor(Math.random() * (endYMax - endYMin)) + endYMin;

    return `M5 ${moveY} Q ${curveX} ${curveY} ${linkWidth - 7} ${endY}`
  }

  createSVG(linkWidth) {
    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");

    svg.setAttribute("width", linkWidth);
    svg.setAttribute("height", "25");

    const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
    const path2 = document.createElementNS("http://www.w3.org/2000/svg", "path");

    let pathD = this.randomizePath(linkWidth);
    let pathD2 = this.randomizePath(linkWidth);

    if(this.rtl === true) {
      pathD = this.reverseMe(pathD);
      pathD2 = this.reverseMe(pathD2);
    }

    svg.appendChild(this.setPath(pathD, this.color1, this.thickness1, this.strokeLinecap));
    svg.appendChild(this.setPath(pathD2, this.color2, this.thickness2, this.strokeLinecap));

    svg.setAttribute("focusable", false);

    return svg;
  }

  reverseMe(path) {
    /* Regex functions borrwed from
    https://github.com/krispo/svg-path-utils/blob/master/src/svg-path-utils.js */
    let pathOperators = path.replace(/[\d,\-\s]+/g, '').split('');
    let pathNums = path.replace(/[A-Za-z,]+/g, ' ').trim().replace(/\s\s+/g, ' ').split(' ');

    return `${pathOperators[0]} ${pathNums[4]} ${pathNums[5]} ${pathOperators[1]} ${pathNums[2]} ${pathNums[3]} ${pathNums[0]} ${pathNums[1]}`;
  }

  // https://plainjs.com/javascript/manipulation/insert-an-element-after-or-before-another-32/
  insertAfter(el, referenceNode) {
    referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
  }
}

let test = new Underliner(".underliner a", "url(#gradient)", "url(#gradient2)", 2, 2, "square", false);


//------------------------------------//
//News Filter Navigation//
//------------------------------------//
$('.news-filter').click(function() {
  $(this).toggleClass('active');
  $('#filter-overlay').toggleClass('open');
  $('body').toggleClass('overflow-hidden');
  $('.main-container').toggleClass('close-filter');
  $('#filter-overlay ul').toggleClass('list-appear');
});


//------------------------------------//
//Object Fit IE Fix//
//------------------------------------//
if ( window && window.Modernizr && !Modernizr.objectfit ) {
  $('.image-container').each(function () {
    var $container = $(this),
      imgUrl = $container.find('img').prop('src');
    if (imgUrl) {
      $container
        .css('backgroundImage', 'url(' + imgUrl + ')')
        .addClass('compat-object-fit');
    }
  });
}